export const frontendUrl = "https://kabelschacht-dev.hsig.ch"

export const apiUrls = {
  authenticationService: "https://kabelschacht-dev-auth.hsig.ch",
  userEndpoint: "https://kabelschacht-dev-auth.hsig.ch/user",
  releaseNotesEndpoint: "https://kabelschacht-dev-auth.hsig.ch/releaseNote.php",
  plansEndpoint: "https://kabelschacht-dev-proj.hsig.ch/plans",
  planOwnerEndpoint: "https://kabelschacht-dev-proj.hsig.ch/changeowner",
  projectsEndpoint: "https://kabelschacht-dev-proj.hsig.ch/projects",
  deliveryAddressEndpoint: "https://kabelschacht-dev-proj.hsig.ch/deliveryaddress.php",
  billingAddressEndpoint: "https://kabelschacht-dev-proj.hsig.ch/billingaddress.php",
  projectStatusEndpoint: "https://kabelschacht-dev-proj.hsig.ch/changestatus",
  planPdfDownload: "https://kabelschacht-dev-proj.hsig.ch/pdfs.php",
  detailsEndpoint: "https://kabelschacht-dev-proj.hsig.ch/detail.php",
  armouringDataEndpoint: "https://kabelschacht-dev-proj.hsig.ch/armouring.php",
  armouringDocumentsEndpoint: "https://mannhart-dev-armouring-factory.hsig.app/"
}

export const mannhartWebsite = "https://mannhart.swiss"
export const termsAndConditions = "https://mannhart.swiss"
export const privacyPolicy = "https://mannhart.swiss"
export const releaseNotes = "https://mannhart.swiss/kabelschacht-app-versionen"
